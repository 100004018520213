/*------------------------------------------------
// File Name:PDM_BodyCheck_PaiBan.js
// File Description:PDM_BodyCheck_PaiBan API方法js接口
// Author:zxl
// Create Time:2023/04/06 15:38:12
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _bodycheckPaiban: "/PDM_BodyCheck_PaiBan"
}

export default api


//-------------------创建排班表对象-------------------
export function CreatePDM_BodyCheck_PaiBanEntity(parameter) {
    return axios({
        url: api._bodycheckPaiban + "/CreatePDM_BodyCheck_PaiBanEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取排班表列表-------------------
export function GetPDM_BodyCheck_PaiBanList(parameter) {
    return axios({
        url: api._bodycheckPaiban + "/GetPDM_BodyCheck_PaiBanList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取排班表列表---------------
export function GetPDM_BodyCheck_PaiBanListByPage(parameter) {
    return axios({
        url: api._bodycheckPaiban + "/GetPDM_BodyCheck_PaiBanListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取排班表对象---------------
export function GetPDM_BodyCheck_PaiBanEntity(parameter) {
    return axios({
        url: api._bodycheckPaiban + "/GetPDM_BodyCheck_PaiBanEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存排班表对象---------------
export function UpdatePDM_BodyCheck_PaiBanEntity(parameter) {
    return axios({
        url: api._bodycheckPaiban+ "/UpdatePDM_BodyCheck_PaiBanEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉排班表对象---------------
export function DeletePDM_BodyCheck_PaiBanEntity(parameter) {
    return axios({
        url: api._bodycheckPaiban + "/DeletePDM_BodyCheck_PaiBanEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------启用/禁用---------------
export function UpateIsDel(parameter) {
    return axios({
        url: api._bodycheckPaiban + "/UpateIsDel",
        method: 'post',
        data: parameter
    })
}

//-------------------生成排班---------------
export function CreateDate(parameter) {
    return axios({
        url: api._bodycheckPaiban + "/CreateDate",
        method: 'post',
        data: parameter
    })
}

//-------------------点击日期重新生成排班---------------
export function ClickDay(parameter) {
    return axios({
        url: api._bodycheckPaiban + "/ClickDay",
        method: 'post',
        data: parameter
    })
}

//-------------------保存排班表对象--上下午时间---------------
export function UpdateDaoRuPDM_BodyCheck_PaiBan(parameter) {
    return axios({
        url: api._bodycheckPaiban + "/UpdateDaoRuPDM_BodyCheck_PaiBan",
        method: 'post',
        data: parameter
    })
}
